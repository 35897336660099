import { Input, InputProps } from "./Input";
import * as dateIsoUtils from "../input.date-iso.utils";

type InputDateProps = Omit<InputProps, "type">;

/**
 * Specific Input for dates (type: 'date-iso')
 * It automatically formats the value to usable dates
 *
 * @param props to generate the component
 */
export function InputDate(props: InputDateProps) {
	const { onChange, value } = props;

	const onChangeDate: typeof onChange = onChange
		? event => onChange(dateIsoUtils.transformEventToDateISO(event))
		: onChange;
	const valueDate = dateIsoUtils.transformValueToDateISO(value);

	return (
		<Input
			{...props}
			onChange={onChangeDate}
			type="date"
			value={valueDate}
		/>
	);
}
