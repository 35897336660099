import * as joy from "@mui/joy";
import { forwardRef } from "react";

import { InputCurrency } from "./InputCurrency";
import { InputDate } from "./InputDate";
import { textBody, textSmall } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";

// The `InputProps` from the lib is not really the one of {@link InputJoy}
type InputJoyProps = Parameters<typeof joy.Input>[0];

/** A pseudo-type for 'date'. Kinda allows `null` as a value. */
export type InputPropsDateType = "date-iso";
/**
 * A pseudo-type for 'currency'
 * 	it is a "sub-type" of text (and currently changes nothing to the input).
 *
 * (Does not seem really to be a good way but ...)
 */
export type InputCurrencyType = "number-currency";
export type InputPropsType =
	| InputCurrencyType
	| InputPropsDateType
	| "date"
	| "email"
	| "number"
	| "password"
	| "text";

/** Props for {@link Input} */
export interface InputProps
	extends Pick<
		InputJoyProps,
		| "autoFocus"
		| "className"
		| "disabled"
		| "endDecorator"
		| "error"
		| "name"
		| "onBlur"
		| "onChange"
		| "onFocus"
		| "onKeyDown"
		| "onKeyUp"
		| "placeholder"
		| "startDecorator"
		| "sx"
		| "tabIndex"
		| "value"
	> {
	/** The type of the input */
	type?: InputPropsType;
}

/**
 * Base component for inputs
 *
 * @param props to generate the component
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
	(props, ref): JSX.Element => {
		if (props.type === "number-currency") {
			return <InputCurrency {...props} />;
		}

		if (props.type === "date-iso") {
			return <InputDate {...props} />;
		}

		const { error, name, value, ...rest } = props;
		// For when we receive 'null' from the backend / initial values (== nullable fields)
		const valueClean = value === null ? "" : value;

		return (
			<InputStyled
				ref={ref}
				data-testid="ui/input"
				id={name}
				{...rest}
				error={!!error}
				name={name}
				value={valueClean}
			/>
		);
	},
);
Input.displayName = "Input";

const InputStyled = joy.styled(joy.Input)<{
	error?: boolean;
}>`
	border-radius: 4px;
	background-color: ${theme.palette.white.primary};
	border: none;
	box-shadow: none;
	color: ${theme.palette.grey[90]};
	height: 42px;
	min-height: 42px;
	outline: ${({ error }) => `1px solid ${error ? theme.palette.transactional.danger : theme.palette.grey[40]}`};
	padding: 4px 8px 4px 12px !important;
	${textBody}
	--Input-focusedThickness: 0;

	& .${joy.inputClasses.input}::placeholder {
		color: ${theme.vars.palette.grey[50]};
	}

	// Used for 'number' inputs, to add spacing next to the number spinner buttons
	& .${joy.inputClasses.input}::-webkit-inner-spin-button {
		margin-left: 4px;
	}

	& .${joy.inputClasses.input} {
		font-variant-numeric: tabular-nums;
	}

	&.${joy.inputClasses.disabled} {
		color: ${theme.vars.palette.grey[45]};
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${joy.inputClasses.disabled} .${joy.inputClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${joy.inputClasses.focused} {
		outline: 2px solid ${theme.palette.blue.primary};
	}

	& .${joy.inputClasses.startDecorator} {
		color: ${theme.vars.palette.blue.primary};
		margin-right: 4px;
		${textSmall}
	}

	& .${joy.inputClasses.endDecorator} {
		color: ${theme.vars.palette.blue.primary};
		${textSmall}
	}
,
`;
