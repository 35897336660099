import { RefObject } from "react";

import { formatInputValue } from "./input.format-value";
import { InputUtils } from "../../../../utils";
import { Formatters } from "../../../../utils/formatters";

/**
 * An onChange override for inputs that should format the value with thousands' separator.
 *
 * @param event the change event
 * @param oldStateValue the old state value
 * @param formatters the formatter functions
 * @param separatorChar the character used as a thousands' separator
 * @param inputRef the input reference (used to manually set the cursor position)
 * @param onChange the parent onChange function
 */
export function handleChangeForThousands(
	event: React.ChangeEvent<HTMLInputElement>,
	oldStateValue: string,
	formatters: Formatters,
	separatorChar: string,
	inputRef: RefObject<HTMLInputElement>,
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
) {
	const newRawValue = event.target.value;

	// Get the state value and the cursor position
	let stateValue = stripSeparator(newRawValue);
	let cursorPosition = event.target.selectionStart || 0;

	// If the entered value if not a number, prevent the change
	if (isNaN(+stateValue)) {
		return;
	}

	// If we tried to delete a thousand separator…
	if (stateValue.length === oldStateValue.length) {
		// …we delete the character before instead
		stateValue =
			stateValue.slice(0, cursorPosition - 1) +
			stateValue.slice(cursorPosition);
		cursorPosition = cursorPosition - 1;
	}

	// Calculate the updated value and cursor position
	const formattedValue = formatInputValue(formatters, {
		type: "number-currency",
		value: stateValue,
	});
	const newCursorPosition = calculateNewCursorPosition(
		newRawValue,
		formattedValue.toString(),
		cursorPosition,
		separatorChar,
	);

	// Escalate the new value to parent onChange (e.g. Formik state)
	if (onChange) {
		void onChange(
			InputUtils.transformChangeEvent(event, (_, __) =>
				stateValue ? +stateValue : "",
			),
		);
	}

	/**
	 * Set the cursor position on the input (using setSelectionRange).
	 * If we don't do that, the cursor will be moved at the end of the input after each value update.
	 * We use a timeout to avoid state and cursor updates to clash.
	 */
	setTimeout(() => {
		if (inputRef.current) {
			inputRef.current
				?.querySelector("input")
				?.setSelectionRange(newCursorPosition, newCursorPosition);
		}
	}, 0);
}

/**
 * A 'value' formatter for inputs that should format the value with thousands' separator.
 *
 * @param value the value to format
 * @param formatters the formatter functions
 * @returns the formatted value
 */
export function formatValueForThousands(value: string, formatters: Formatters) {
	return formatInputValue(formatters, {
		type: "number-currency",
		value: value,
	});
}

// TODO: move that elsewhere
function stripSeparator(value: string): string {
	return value.replace(/\D/g, "");
}

const calculateNewCursorPosition = (
	oldValue: string,
	newValue: string,
	oldCursorPosition: number,
	separatorChar: string,
): number => {
	const regexp = new RegExp(separatorChar, "g");
	const oldValueBeforeCursor = oldValue
		.slice(0, oldCursorPosition)
		.replace(regexp, "");
	let newCursorPosition = 0;
	for (let charCount = 0, i = 0; i < newValue.length; i++) {
		if (newValue[i] !== separatorChar) {
			charCount++;
		}
		if (charCount > oldValueBeforeCursor.length) {
			break;
		}
		newCursorPosition = i + 1;
	}
	return newCursorPosition;
};
