import { useRef } from "react";

import { Input, InputProps } from "./Input";
import * as inputMasks from "./input.masks";
import {
	useFormatters,
	useFormattersUtils,
} from "../../../../utils/formatters";

type InputCurrencyProps = Omit<InputProps, "type">;

/**
 * Specific Input for currency (type: 'number-currency')
 * It automatically formats the value with a thousands' separator
 *
 * @param props to generate the component
 */
export function InputCurrency(props: InputCurrencyProps) {
	const { onChange, value } = props;

	const formatters = useFormatters();
	const { separatorChar } = useFormattersUtils();

	// Needed to handle cursor positioning in currency mask handler
	const inputRef = useRef<HTMLInputElement>(null);

	const currentStateValue = value?.toString() ?? "";

	const onChangeNumber: typeof onChange = event =>
		inputMasks.handleChangeForThousands(
			event,
			currentStateValue,
			formatters,
			separatorChar,
			inputRef,
			onChange,
		);
	const valueNumber = inputMasks.formatValueForThousands(
		currentStateValue,
		formatters,
	);

	return (
		<Input
			{...props}
			ref={inputRef}
			onChange={onChangeNumber}
			value={valueNumber}
			type="text"
		/>
	);
}
