import { TFunction } from "next-i18next";
import { KeysOfUnion } from "type-fest";
import * as z from "zod";

/** @internal */
function invalidStringFormat(
	t: TFunction,
	validation: Extract<z.StringValidation, string>,
): string {
	switch (validation) {
		case "email":
			return t("errors.zod.invalid-string-format.email");
		case "url":
			return t("errors.zod.invalid-string-format.url");
		case "date":
		case "datetime":
			return t("errors.zod.invalid-string-format.date");
		case "regex":
			return t("errors.zod.invalid-string-format.regex");

		// Not managed (yet)
		case "base64":
		case "base64url":
		case "cidr":
		case "cuid":
		case "cuid2":
		case "duration":
		case "emoji":
		case "ip":
		case "jwt":
		case "nanoid":
		case "ulid":
		case "time":
		case "uuid":
			return t("errors.zod._default");
	}
}

/**
 * Translates {@link z.ZodInvalidStringIssue}
 *
 * @param t translate function
 * @param issue to translate
 * @returns translation
 */
export function invalidString(t: TFunction, issue: z.ZodInvalidStringIssue) {
	const { validation } = issue;
	if (typeof validation === "string") {
		return invalidStringFormat(t, validation);
	}

	// Remaining `validation` is an union of object with these keys to discriminate them
	const [kInclude, kStarts, kEnds] = [
		"includes",
		"startsWith",
		"endsWith",
	] as const satisfies Array<KeysOfUnion<typeof validation>>;

	switch (true) {
		case kInclude in validation:
			return t("errors.zod.invalid-string.includes", {
				text: validation.includes,
			});
		case kStarts in validation:
			return t("errors.zod.invalid-string.starts-with", {
				text: validation.startsWith,
			});
		case kEnds in validation:
			return t("errors.zod.invalid-string.ends-with", {
				text: validation.endsWith,
			});
	}

	// `validation` is of type `never`
	return validation;
}
